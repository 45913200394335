import Vue from "vue";
import { auth } from "../lib/firebase";
import { log } from "../lib/utils";

import type firebase from "firebase/compat/app";

export type User = firebase.User;
type UserCredential = firebase.auth.UserCredential;
type IdTokenResult = firebase.auth.IdTokenResult;

export type UserRole = "retail" | "vouchers" | "wholesale" | "stats" | "cms";

export interface AuthState {
	authorized: boolean;
	user: User;
	userType?: "admin" | "factory" | "custom";
	roles?: Array<UserRole>;
}

interface Component extends Vue {
	authState: AuthState;
}

// https://firebase.google.com/docs/auth/web/multi-factor#signing_users_in_with_a_second_factor
async function signInWithURL(email: string | null): Promise<UserCredential> {
	if (email === null) {
		return Promise.reject();
	} else {
		// Could get UserCredential.additionalUserInfo.isNewUser: boolean
		return auth().signInWithEmailLink(email, location.href);
	}
}

function onComponentMouted(this: Component): void {
	const authState: AuthState = this.authState;
	const recaptchaVerifier = new auth.RecaptchaVerifier(
		"recaptcha-container",
		{
			size: "invisible",
		},
	);

	auth().onAuthStateChanged((user: User | null): void => {
		const email: string | null = localStorage.getItem("userEmail");
		if (user === null) {
			let resolver: firebase.auth.MultiFactorResolver;
			signInWithURL(email).catch((error) => {
				if (error?.code === "auth/multi-factor-auth-required") {
					const selectedIndex = 0;
					resolver = error.resolver;
					// Ask user which second factor to use.
					if (
						resolver.hints[selectedIndex]?.factorId ===
						auth.PhoneMultiFactorGenerator.FACTOR_ID
					) {
						var phoneInfoOptions = {
							multiFactorHint: resolver.hints[selectedIndex],
							session: resolver.session,
						};
						var phoneAuthProvider = new auth.PhoneAuthProvider();
						async function onVerifySuccess(verificationId: string) {
							// Ask user for the SMS verification code.
							var cred = auth.PhoneAuthProvider.credential(
								verificationId,
								String(
									prompt(
										"Please insert your SMS verification code",
									),
								),
							);
							var multiFactorAssertion =
								auth.PhoneMultiFactorGenerator.assertion(cred);
							// Complete sign-in.
							return resolver
								.resolveSignIn(multiFactorAssertion)
								.catch(() => {
									alert(
										"Wrong verification code, please try again",
									);
									onVerifySuccess(verificationId);
								});
						}
						// Send SMS verification code
						return phoneAuthProvider
							.verifyPhoneNumber(
								phoneInfoOptions,
								recaptchaVerifier,
							)
							.then(onVerifySuccess);
					}
				}

				localStorage.removeItem("userEmail");
				authState.authorized = false;
			});
		} else {
			const currentPath = location.pathname.toString();
			const params = new URLSearchParams(location.search);
			// Strip firebase auth params
			if (params.get("apiKey")) {
				params.delete("apiKey");
				params.delete("oobCode");
				params.delete("mode");
				params.delete("lang");
				history.replaceState(undefined, "", `${currentPath}?${params}`);
			}
			if (user.email !== null) {
				localStorage.setItem("userEmail", user.email);
			}
			user.getIdTokenResult()
				.then((idTokenResult: IdTokenResult) => {
					if (idTokenResult.claims.admin === true) {
						authState.userType = "admin";
						authState.roles = [
							"retail",
							"vouchers",
							"wholesale",
							"stats",
						];
					}
					if (idTokenResult.claims.factory === true) {
						authState.userType = "factory";
						authState.roles = ["retail", "wholesale"];
					}
					if (idTokenResult.claims.roles) {
						if (!authState.userType) authState.userType = "admin";
						authState.roles = idTokenResult.claims.roles;
					}

					if (authState.userType === undefined) {
						auth()
							.signOut()
							.then((): void => {
								alert("Access Forbidden");
							})
							.catch(log.error);
					} else {
						authState.authorized = true;
						authState.user = user;
					}
				})
				.catch(log.error);
		}
	});
}

export default Vue.component("app-auth", {
	mounted: onComponentMouted,
	props: ["authState"],
	template: `<div></div>`,
});
