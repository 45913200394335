import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

const firebaseConfig = JSON.parse(
	import.meta.env.VITE_FIREBASE_CONFIG as string,
);
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ ignoreUndefinedProperties: true });

if (import.meta.env.DEV) {
	const host = "localhost";
	firebase.auth().useEmulator(`http://${host}:9099`);
	firebase.firestore().useEmulator(host, 8080);
	firebase.functions().useEmulator(host, 5001);
}

export const analytics = firebase.analytics;
export const auth = firebase.auth;
export const firestore = firebase.firestore;
export const functions = firebase.functions;
export const storage = firebase.storage;
export const apiKey = firebaseConfig.apiKey;
