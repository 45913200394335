import Vue from "vue";
import { functions, storage } from "../lib/firebase";
import { getOrderLastEvent, fmtOrderRef } from "@launerlondon/shared";
import type {
	RetailOrder,
	Event,
	EventType,
	EventApproved,
	EventShipped,
} from "@launerlondon/shared";
import { isCartDigital } from "@launerlondon/products";

import "./appModal";
import "./retailOrderTransaction";

type ModalFlags = {
	showApprovalModal: boolean;
	showDispatchModal: boolean;
	showStartModal: boolean;
	showCancelModal: boolean;
	showCreateReturnModal: boolean;
	showProcessReturnModal: boolean;
};

type Component = Vue &
	ModalFlags & {
		orderId: string;
		userType: "admin" | "factory";
		notesFactory?: string;
		eventType?: string;
		eventMessage?: string;
		eventUrl?: string;
		fraudScore?: number;
		order: RetailOrder;
		shippingInfo: EventShipped;
		lastActionLabel: string;
	};

async function pushEvent<T>(
	orderId: string,
	event: Omit<Event<T>, "date">,
): Promise<void> {
	//Date is sent as millis as https call doesn't convert date to timestamp
	await functions()
		.httpsCallable("onRetailOrderPushEventCall")({
			orderId,
			event: {
				...JSON.parse(JSON.stringify(event)),
				date: new Date().valueOf(),
			},
		})
		.catch((err) => {
			alert(err.message);
			throw err;
		});
}

async function approveOrder(this: Component): Promise<void> {
	await pushEvent<EventApproved>(this.orderId, {
		type: "approved",
		message: this.notesFactory,
		extra: { fraudScore: this.fraudScore },
	});
	this.showApprovalModal = false;
}

async function cancelOrder(this: Component): Promise<void> {
	await pushEvent(this.orderId, {
		type: "cancelled",
		message: this.notesFactory,
	});
	this.showCancelModal = false;
}

async function startOrder(this: Component): Promise<void> {
	await pushEvent(this.orderId, { type: "started" });
	this.showStartModal = false;
}

async function dispatchOrder(this: Component): Promise<void> {
	await pushEvent<EventShipped>(this.orderId, {
		type: "shipped",
		extra: this.shippingInfo,
	});
	this.showDispatchModal = false;
}

async function createReturn(this: Component): Promise<void> {
	let url;

	if (this.eventUrl?.startsWith("blob:")) {
		const file = (await fetch(this.eventUrl).then((r) => r.blob())) as File;
		if (location.hostname === "localhost") {
			storage().useEmulator("localhost", 9199);
		}
		const orderId = this.orderId;
		const orderRef = fmtOrderRef(this.order.ref);
		const r = await storage()
			.ref(`pdf/return-label-${orderRef}-${orderId}-${Date.now()}.pdf`)
			.put(file);
		url = await r.ref.getDownloadURL();
	}
	await pushEvent<Event>(this.orderId, {
		type: "return_created",
		message: this.eventMessage,
		url,
	});
	this.showCreateReturnModal = false;
}

async function processReturn(this: Component): Promise<void> {
	if (this.eventType === "refunded" || this.eventType === "repair_started") {
		await pushEvent<EventShipped>(this.orderId, {
			type: this.eventType,
			message: this.eventMessage,
		});
		this.showProcessReturnModal = false;
	}
}

async function uploadFile(this: Component, event: InputEvent) {
	const target = event.target as HTMLInputElement;
	const file = target.files?.item(0);
	if (file) {
		if (file.size > 1 * 1024 * 1024) {
			alert("Files cannot be larger than 3MB");
			target.value = "";
			return;
		}
		this.eventUrl = URL.createObjectURL(file);
	}
}

const modals = {
	approval: `
		<app-modal v-if=showApprovalModal @close='showApprovalModal=false'>
			<form @submit.prevent=approveOrder>
			<h2 class=app-modal__title>Send order #{{fmtOrderRef(order.ref)}} to the factory for production?</h2>
			<p>
				Please check that the payment has been received by the customer
				and that any notes and special requirements have been added to
				this order before approving.
			</p>

			<label class='app-field mt-2'>
				<span class=app-field__label>Fraud score</span>
				<input class=app-field__input v-model.number=fraudScore placeholder=45 type=number>
			</label>

			<button class=app-button type=button @click='showApprovalModal=false'>Go back</button>
			<button class=app-button type=submit>Submit</button>
			</form>
		</app-modal>
	`,
	cancel: `
		<app-modal v-if=showCancelModal @close='showCancelModal=false'>
			<form @submit.prevent=cancelOrder>
			<h2 class=app-modal__title>Cancel order #{{fmtOrderRef(order.ref)}}?</h2>
			<p>
				Please note that once an order has been cancelled, it's
				no longer possible to re-open it.
			</p>
			<label class="app-field mt-2">
				<span class="app-field__label p-0">Reason for cancelling (internal only)</span>
				<textarea class="app-field__input min-h-[100px]" v-model=notesFactory placeholder='i.e: Cancelled with order #1234 as a replacement'/>
			</label>
			<button class=app-button type=button @click='showCancelModal=false'>Go back</button>
			<button class=app-button type=submit>Submit</button>
			</form>
		</app-modal>
	`,
	dispatch: `
		<app-modal v-if=showDispatchModal @close='showDispatchModal=false'>
			<form @submit.prevent=dispatchOrder>
				<h2 class=app-modal__title>Mark order #{{fmtOrderRef(order.ref)}} as completed and dispatched?</h2>
				<template v-if='isDigitalOnly'>
					<p>This order contains only digital items, mark it as completed?</p>
				</template>
				<template v-else>
					<p>
						Upon submit, an email will be sent to the customer with their
						tracking number and other details.
					</p>
					<div class=app-field__group>
						<label class=app-field>
							<span class=app-field__label>Tracking number</span>
							<input class=app-field__input v-model=shippingInfo.trackingNumber required>
						</label>
						<label class=app-field>
							<span class=app-field__label>Shipping method</span>
							<select class=app-field__input v-model=shippingInfo.company required>
								<option>DHL Parcel UK</option>
								<option>DSV</option>
								<option>Parcelforce</option>
								<option>Royal Mail</option>
							</select>
						</label>
					</div>
					<p>
						<small>
							Full order details will be stored in the cloud for future
							reference.
						</small>
					</p>
				</template>
				<button type=button class=app-button @click='showDispatchModal=false'>Go back</button>
				<button type=submit class=app-button>Submit</button>
			</form>
		</app-modal>
	`,
	start: `
		<app-modal v-if=showStartModal @close='showStartModal=false'>
			<form @submit.prevent=startOrder>
			<h2 class=app-modal__title>Start order #{{fmtOrderRef(order.ref)}} production</h2>
			<p>Mark the start of the production for this order?</p>
			<button class=app-button type=button @click='showStartModal=false'>Go back</button>
			<button class=app-button type=submit>Start</button>
			</form>
		</app-modal>
	`,
	transaction: `
		<app-modal v-if=showTransactionModal @close='showTransactionModal=false'>
			<retail-order-transaction :order=order @close='showTransactionModal=false'>
			</retail-order-transaction>
		</app-modal>
	`,
	createReturn: `
		<app-modal v-if=showCreateReturnModal @close='showCreateReturnModal=false'>
			<form @submit.prevent=createReturn>
				<h2 class=app-modal__title>Create a return for order #{{fmtOrderRef(order.ref)}}</h2>
				<p>Would you like to create a return for this order?</p>
				<label class="app-field mt-2">
					<span class="app-field__label p-0">Reason for return (public to customer)</span>
					<textarea class="app-field__input min-h-[100px]" v-model=eventMessage placeholder='i.e: Traviata has a defective flap magnet' required/>
				</label>
				<label class=app-field>
					<span class=app-field__label>Return label (optional / PDF Max 1Mb)</span>
					<input class=app-field__input accept='application/pdf' type=file v-on:change=uploadFile>
				</label>
				<button class=app-button type=button @click='showCreateReturnModal=false'>Go back</button>
				<button class=app-button type=submit>Create Return</button>
			</form>
		</app-modal>
	`,
	processReturn: `
		<app-modal v-if=showProcessReturnModal @close='showProcessReturnModal=false'>
			<form @submit.prevent=processReturn>
			<h2 class=app-modal__title>Order #{{fmtOrderRef(order.ref)}} Return</h2>
			<p>How would you like to proceed with the return for this order?</p>
			<div class="flex gap-2 items-center justify-center mt-6 mb-4">
				<label>
					<input hidden class='peer' type='radio' name='action' value='refunded' required v-model='eventType' @input="event => eventMessage = undefined"/>
					<span class='app-button peer-checked:bg-green-200'>Refund</span>
				</label>
				<label>
					<input hidden class='peer' type='radio' name='action' value='repair_started' required v-model='eventType' @input="event => eventMessage = lastEvent.message"/>
					<span class='app-button peer-checked:bg-green-200'>Repair</span>
				</label>
			</div>
			<div v-if='eventType === "refunded"'>
				<p class='text-sm bg-yellow-50 text-amber-700 p-2 rounded border border-yellow-400'>
					Remember, actions here won't effectively refund the customer.
					To do so, please proceed from the Stripe portal and
					continue here after the refund has been issued.</br>
					<a class='underline' :href="stripeUrl" target="_blank">Stripe Dashboard Order</a>
				</p>
				<label class="app-field mt-2">
					<span class="app-field__label p-0">Customer notes</span>
					<textarea class="app-field__input min-h-[100px]" v-model=eventMessage placeholder='i.e: Partial refund of 170 GBP for phone case' required/>
				</label>
			</div>
			<div v-if='eventType === "repair_started"'>
				<label class="app-field mt-2">
					<span class="app-field__label p-0">Internal notes</span>
					<textarea class="app-field__input min-h-[100px]" v-model="eventMessage" placeholder='i.e: Traviata has a defective flap magnet' required/>
				</label>
			</div>
			<button class=app-button type=button @click='showProcessReturnModal=false'>Go back</button>
			<button v-if='eventType' class=app-button type=submit>Submit</button>
			</form>
		</app-modal>
	`,
};

export default Vue.component("retail-order-action", {
	data() {
		return {
			orderId: this.$route.params.orderId,
			fraudScore: undefined,
			notesFactory: undefined,
			eventType: undefined,
			eventMessage: undefined,
			eventUrl: undefined,
			shippingInfo: {},
			showApprovalModal: false,
			showCancelModal: false,
			showDispatchModal: false,
			showStartModal: false,
			showTransactionModal: false,
			showCreateReturnModal: false,
			showProcessReturnModal: false,
		};
	},
	computed: {
		stripeUrl(this: Component): string {
			const event = getOrderLastEvent(this.order, "charged");
			return new URL(
				event?.extra?.sourceId as string,
				"https://dashboard.stripe.com/payments",
			).href;
		},
		shouldShowComponent(this: Component): boolean {
			const event: Event = getOrderLastEvent(this.order);
			const { type } = event;
			if (this.userType === "admin") {
				return (
					type === "pending" ||
					type === "placed" ||
					type === "charged" ||
					type === "failed" ||
					type === "approved" ||
					type === "started" ||
					type === "shipped" ||
					type === "return_created"
				);
			}
			if (this.userType === "factory") {
				return (
					type === "approved" ||
					type === "started" ||
					type === "return_created" ||
					type === "repair_started"
				);
			}

			return false;
		},
		shouldShowCancelAction(this: Component): boolean {
			const event: Event = getOrderLastEvent(this.order);
			return (
				this.userType === "admin" &&
				(event.type === "approved" || event.type === "started")
			);
		},
		lastEvent(this: Component): Event {
			return getOrderLastEvent(this.order);
		},
		lastActionLabel(this: Component): EventType {
			return getOrderLastEvent(this.order).type;
		},
		isDigitalOnly(this: Component) {
			return isCartDigital(this.order.items);
		},
	},
	methods: {
		fmtOrderRef,
		approveOrder,
		cancelOrder,
		dispatchOrder,
		startOrder,
		createReturn,
		processReturn,
		uploadFile,
		showModal(this: Component, key: keyof ModalFlags) {
			this.eventType = undefined;
			this.eventMessage = undefined;
			this.eventUrl = undefined;
			this[key] = true;
		},
	},
	props: ["order", "userType"],
	template: `
	<div class=retail-order-action v-if=shouldShowComponent>
		<div class='retail-order-action__container pt-2 pb-4'>
			<h4 class=retail-order-action__title>Next action</h4>
			<button class=app-button v-if='lastActionLabel==="pending"' @click='showTransactionModal=true'>Push order</button>
			<button class=app-button v-if='lastActionLabel==="placed"' @click='showTransactionModal=true'>Add payment info</button>
			<button class=app-button v-if='lastActionLabel==="charged"' @click='showCancelModal=true'>Cancel order</button>
			<button class=app-button v-if='lastActionLabel==="charged"' @click='showApprovalModal=true'>Approve production</button>
			<button class=app-button v-if='lastActionLabel==="failed"' @click='showTransactionModal=true'>Retry payment</button>
			<button class=app-button v-if='shouldShowCancelAction' @click='showCancelModal=true'>Cancel order</button>
			<button class=app-button v-if='userType === "admin" && lastActionLabel==="approved" && isDigitalOnly' @click='showDispatchModal=true'>Complete order</button>
			<button class=app-button v-if='userType === "factory" && lastActionLabel==="approved" && !isDigitalOnly' @click='showStartModal=true'>Start production</button>
			<button class=app-button v-if='userType === "factory" && lastActionLabel==="started"' @click='showDispatchModal=true'>Mark as dispatched</button>
			<button class=app-button v-if='userType === "factory" && lastActionLabel==="repair_started"' @click='showDispatchModal=true'>Mark as dispatched</button>
			<button class=app-button v-if='userType === "admin" && lastActionLabel==="shipped" && !isDigitalOnly' @click='showModal("showCreateReturnModal")'>Create a return</button>
			<button class=app-button v-if='lastActionLabel==="return_created" && !isDigitalOnly' @click='showModal("showProcessReturnModal")'>Process Return</button>
		</div>

		${modals.approval}
		${modals.transaction}
		${modals.start}
		${modals.dispatch}
		${modals.cancel}
		${modals.createReturn}
		${modals.processReturn}
	</div>
	`,
});
