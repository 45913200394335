import runFromNow from "fromnow";

type LogTypes = "error" | "info" | "warn";
type LogFn = (message: string | object | Error | undefined) => void;
interface Log {
	error: LogFn;
	info: LogFn;
	warn: LogFn;
}
function sendLog(type: LogTypes, msg: string | Error | undefined): void {
	const message: string | undefined =
		msg instanceof Error ? msg.message : msg;
	// tslint:disable-next-line strict-type-predicates
	const hasAlert = typeof alert === "function";
	console[type](message);
	if (type === "error" && hasAlert) {
		alert(message);
	}
}
export const log: Log = {
	error: sendLog.bind(undefined, "error") as LogFn,
	info: sendLog.bind(undefined, "info") as LogFn,
	warn: sendLog.bind(undefined, "warn") as LogFn,
};

export function fromNow(date: string | null): string {
	if (date === null) {
		return "never";
	}

	return runFromNow(date);
}

export function fmtCurrency(
	amount: number = 0,
	round?: boolean,
	prefix?: string,
): string {
	if (isNaN(amount)) return amount.toString();
	const decimals = 2;
	let value = amount.toFixed(decimals).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
	if (round === true) {
		value = value.replace(/\.\d{2}$/, "");
	}
	if (prefix !== undefined) {
		return `${prefix}${value}`;
	}

	return value;
}

export function uuid(): string {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
		/[xy]/g,
		(c: string) => {
			const r = (Math.random() * 16) | 0; // tslint:disable-line
			const v = c === "x" ? r : (r & 0x3) | 0x8; // tslint:disable-line
			return v.toString(16); // tslint:disable-line
		},
	);
}

function currencyToLocale(code: string): string {
	const langs = {
		AED: "en-AE",
		AUD: "en-AU",
		BRL: "pt-BR",
		CAD: "en-CA",
		CHF: "de-CH",
		CNY: "zh-CN",
		DKK: "da-DK",
		EUR: "de-DE",
		GBP: "en-GB",
		HKD: "zh-HK",
		NOK: "no-NO",
		NZD: "en-NZ",
		SEK: "sv-SE",
		SGD: "zh-SG",
		USD: "en-US",
	};
	if (code in langs) {
		const c = code as keyof typeof langs;
		return langs[c];
	}
	return "en-GB";
}

export function fmtPrice(
	amount: number,
	currency = "GBP",
	currencyDisplay: Intl.NumberFormatOptionsCurrencyDisplay = "symbol",
): string {
	const locale = currencyToLocale(currency);
	return amount.toLocaleString(locale, {
		style: "currency",
		currency: currency === "base" ? "GBP" : currency,
		currencyDisplay,
	});
}
